"use client";

import { typography } from "@evolve-storefront/ui/base/Typography/typography";
import type { ReactNode } from "react";

export default function GlobalError({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}): ReactNode {
	console.error(error);
	return (
		<html lang="en">
			<body>
				<h2 className={typography("h2")}>Something went wrong!</h2>
				<button type="reset" onClick={() => reset()}>
					Try again
				</button>
			</body>
		</html>
	);
}
